.field-border {
  border-color: #d55c5c;
}
.field-border:focus {
  border-color: #d55c5c;
}
.error-msg {
  padding: 5px 0px 0 11px;
  color: #d55c5c;
  font-size: 13px;
}

:root {
  --primary: #5bc142;
  --secondary: #262e37f2;

  --light: #5bc14259;
  --dark: #262e37f2;
}
.App {
  text-align: center;
}
html {
  font-family: revert;
  letter-spacing: 0.2px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-control:focus {
  border-color: green !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: 2px solid #0db8de;
  outline: 0;
  background-color: #edf0f5;
}
.cp {
  cursor: pointer;
}
.divider {
  margin-top: 5px;
  margin-bottom: 5px;
  height: 1px;
  width: 100%;
  border-top: 1px solid #80808061;
}
.ag-watermark {
  display: none;
}
.ag-watermark-text {
  display: none;
}
/* SCROLL BAR */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c6e9bd;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c6e9bd;
}

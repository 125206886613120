/********** Template CSS **********/
:root {
  /* --primary: #eb1616;
  --secondary: #191c24;
  --light: #6c7293;
  --dark: #000000; */

  /* --primary: #eb1616; */
  /* --secondary: #262e37f2; */

  --light: #e9edf1;
  /* --dark: #262e37f2; */
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}

/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity 0.5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

/*** Button ***/
.btn {
  transition: 0.5s;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 50px;
}

/*** Layout ***/
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 240px;
  height: 100vh;
  overflow-y: auto;
  background: #ffffff;
  transition: 0.5s;
  z-index: 999;
}

.content {
  /* min-height: 100vh; */
  background: #e5e1e1;
  transition: 0.5s;
}

/*** Navbar ***/
/* .navbar {
  height: 10%;
} */
.nav-link {
  font-size: 14px !important;
  /* text-align: center; */
}
.nav-link svg {
  color: rgb(137, 157, 193);
  font-size: 25px;
}
.nav-link.active svg {
  color: #ffff;
  font-size: 25px;
}
.sidebar .navbar .navbar-nav .nav-link {
  /* padding: 7px 20px; */
  height: 3rem;
  padding: 2px;
  color: var(--light);
  font-weight: 500;
  border-left: 3px solid var(--secondary);
  border-radius: 0 30px 30px 0;
  outline: none;
}

.sidebar .navbar .navbar-nav .nav-link:hover,
.sidebar .navbar .navbar-nav .nav-link.active {
  color: var(--primary);
  background: var(--dark);
  border-color: var(--primary);
  svg {
    color: #ffffff;
  }
}

.sidebar .navbar .navbar-nav .nav-link i {
  padding: 5px;
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var(--dark);
  border-radius: 40px;
}

.sidebar .navbar .navbar-nav .nav-link:hover i,
.sidebar .navbar .navbar-nav .nav-link.active i {
  background: var(--secondary);
}

.sidebar .navbar .dropdown-item {
  padding-left: 25px;
  border-radius: 0 30px 30px 0;
  color: var(--light);
}

.sidebar .navbar .dropdown-item:hover,
.sidebar .navbar .dropdown-item.active {
  background: #88ca78;
  color: var(--dark);
}

.content .navbar .navbar-nav .nav-link {
  margin-left: 25px;
  padding: 12px 0;
  color: var(--light);
  outline: none;
}

.content .navbar .navbar-nav .nav-link:hover,
.content .navbar .navbar-nav .nav-link.active {
  color: var(--primary);
}

.content .navbar .sidebar-toggler,
.content .navbar .navbar-nav .nav-link i {
  width: 40px;
  height: 40px;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var(--dark);
  border-radius: 40px;
  color: #ffff;
}

.content .navbar .dropdown-item {
  color: var(--dark);
}

.content .navbar .dropdown-item:hover,
.content .navbar .dropdown-item.active {
  background: #88ca78;
  color: var(--dark);
}
.dropdown-toggle::after {
  display: none;
}
/*** Date Picker ***/
.bootstrap-datetimepicker-widget.bottom {
  top: auto !important;
}

.bootstrap-datetimepicker-widget .table * {
  border-bottom-width: 0px;
}

.bootstrap-datetimepicker-widget .table th {
  font-weight: 500;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  padding: 10px;
  border-radius: 2px;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background: var(--primary);
}

.bootstrap-datetimepicker-widget table td.today::before {
  border-bottom-color: var(--primary);
}

/*** Testimonial ***/
.progress .progress-bar {
  width: 0px;
  transition: 2s;
}

/*** Testimonial ***/
.testimonial-carousel .owl-dots {
  margin-top: 24px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.testimonial-carousel .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  width: 15px;
  height: 15px;
  border: 5px solid var(--primary);
  border-radius: 15px;
  transition: 0.5s;
}

.testimonial-carousel .owl-dot.active {
  background: var(--dark);
  border-color: var(--primary);
}
.bg-dark {
  background-color: var(--secondary) !important;
}
.body-color {
  background-color: #ffffff;
  color: hsl(0, 3%, 54%);
}
h6 {
  color: var(--secondary);
  margin-top: 0;
  font-size: 1.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  line-height: 1.2;
}
.container-fluid {
  overflow-x: hidden;
  --bs-gutter-x: none !important;
}
p {
  font-weight: 500;
  font-size: 13px !important;
  line-height: 1.7;
}
.header {
  background-color: var(--primary);
  height: 4rem;
}

.open-sidebar {
  margin-left: 6rem;
}
.closed-sidebar {
  margin-left: 0rem;
}
.active {
  background: #5bc14259;
  font-weight: 600;
  svg {
    color: #000000;
  }
}

.menu-item {
  padding: 5px 0 5px 5px;
  font-size: 14px;
}
.logo-img {
  font-size: 14px;
  img {
    width: 3rem;
  }
}
.logo-text {
  font-size: 21px;
  color: #e9edf1;
  font-weight: 500;
  font-family: cursive;
}
.nav-header {
  font-size: 17px;
  color: #191919;
  font-weight: 500;
  font-family: revert;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .logo-text {
    font-size: 15px;
    color: #e9edf1;
    font-weight: 500;
    font-family: cursive;
  }
  .content .container-fluid {
    padding: 15px 0px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .logo-text {
    font-size: 15px;
    color: #e9edf1;
    font-weight: 500;
    font-family: cursive;
  }
  .content .container-fluid {
    padding: 15px 0px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .logo-text {
    font-size: 15px;
    color: #e9edf1;
    font-weight: 500;
    font-family: cursive;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .sidebar {
    margin-left: 0;
  }
  .closed-sidebar {
  }
  .menu-icon {
    display: none;
  }
  .content .navbar .sidebar-toggler {
    /* display: none; */
  }
  .sidebar.open {
    margin-left: -250px;
  }

  .content {
    width: calc(100% - 250px);
    margin-left: 15rem;
  }
  .content .container-fluid {
    padding: 20px;
  }
  .content.open {
    width: 100%;
    margin-left: 0;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
@media (min-width: 992px) {
}

@media (max-width: 991.98px) {
  .sidebar {
    margin-left: -250px;
  }

  .sidebar.open {
    margin-left: 0;
  }
  .content.open {
    width: 100%;
    /* margin-left: 107px; */
  }
  .content {
    width: 100%;
    margin-left: 0;
  }
}
.nav-items .nav-item {
  padding: 5px 20px;
  font-size: 17px;
  font-family: nexa xbold;
  img {
    width: 20px;
    margin-right: 10px;
  }
}
